// Angular
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// Material
import { MatTableModule } from '@angular/material/table';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatBadgeModule } from '@angular/material/badge';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTabsModule } from '@angular/material/tabs';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTooltipModule } from '@angular/material/tooltip';

// Bootstrap
import { NgbModule, NgbToastModule } from '@ng-bootstrap/ng-bootstrap';

// Http
import { HttpClientModule } from '@angular/common/http';

// Forms
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// Services
import { FreSourceService } from './fre-source.service';

@NgModule({
    declarations: [],

    imports: [
      // Angular
      CommonModule,

      // Material
      MatIconModule,
      MatButtonModule,
      MatSidenavModule,
      MatToolbarModule,
      MatListModule,
      MatMenuModule,
      MatCheckboxModule,
      MatFormFieldModule,
      MatTableModule,
      MatTableModule,
      MatProgressSpinnerModule,
      MatInputModule,
      MatSelectModule,
      MatRadioModule,
      MatCardModule,
      MatGridListModule,
      MatBadgeModule,
      MatExpansionModule,
      MatTooltipModule,
      // Bootstrap
      NgbModule,
      NgbToastModule,
      // Http
      HttpClientModule,
      // Forms
      FormsModule,
      ReactiveFormsModule,
    ],

    exports: [
      // Material
      MatIconModule,
      MatButtonModule,
      MatSidenavModule,
      MatToolbarModule,
      MatListModule,
      MatMenuModule,
      MatCheckboxModule,
      MatFormFieldModule,
      MatTabsModule,
      MatTableModule,
      MatProgressSpinnerModule,
      MatInputModule,
      MatSelectModule,
      MatRadioModule,
      MatCardModule,
      MatGridListModule,
      MatBadgeModule,
      MatExpansionModule,
      MatTooltipModule,
      // Bootstrap
      NgbModule,
      NgbToastModule,
      // Http
      HttpClientModule,
      // Forms
      FormsModule,
      ReactiveFormsModule,
    ],

    providers: [FreSourceService]
})

export class GlobalModule {}
