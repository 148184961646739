<div class="spaced">
  <div class="logo-and-name" *ngIf="mainHeader">
    <a routerLink="/">
      <img id="logotipo" [src]="mainHeader.SIT_IMAGEM" alt="Logotipo">
    </a>
    <a routerLink="/" id="nome">{{mainHeader.SIT_DESCRICAO}}</a>
  </div>
  <div class="complete-menu">
    <div class="ui secondary menu spaced">
      <ng-container *ngFor="let item of headerItems">
        <a class="item" [routerLink]="item.IHR_LINK" *ngIf="item.IHR_TITULO !== 'Produtos'; else elseBlock">
          {{item.IHR_TITULO}}
        </a>
      </ng-container>

      <ng-template #elseBlock>
        <a class="ui simple dropdown item">
          <span routerLink="/produtos">
            Produtos
          </span>
          <i class="dropdown icon"></i>
          <div class="menu">
            <ng-container *ngFor="let categoria of categorias">
              <a class="item" (click)="navigate(categoria)">{{categoria.CSI_NOME}}</a>
            </ng-container>
          </div>
        </a>
      </ng-template>

      <div *ngIf="hasSearch">
        <div class="item">
          <div class="ui icon input">
            <input type="text" placeholder="Pesquisar...">
            <i class="search link icon"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<div class="mobile-nav">
  <div class="collapse" id="navbarToggleExternalContent">
    <div class="bg-white p-4">
      <div class="logo-and-name">
        <div class="ui link list">
          <a *ngFor="let item of headerItems" class="item menu-item"
              [routerLink]="item.IHR_LINK" (click)="onChangePlace()">{{item.IHR_TITULO}}</a>
        </div>
      </div>
    </div>
  </div>
  <div class="nav-logo">
    <nav class="navbar navbar-light bg-white">
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarToggleExternalContent"
        aria-controls="navbarToggleExternalContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
    </nav>
    <a routerLink="/">
      <img *ngIf="mainHeader" [src]="mainHeader.SIT_IMAGEM" alt="Logotipo">
    </a>
  </div>
</div>
