import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { map, switchMap } from 'rxjs/operators';
import { FreSourceService } from '../fre-source.service';
import { Categoria } from '../interfaces';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  mainHeader;

  headerItems;

  hasSearch = false;

  categorias = [];

  constructor(private source: FreSourceService,
              private router: Router) { }

  ngOnInit(): void {
    this.source.getCategorias().pipe(
      switchMap((res) => {
        this.categorias = res;
        return this.source.getSiteInfo();
      }),
      switchMap((site) => {
        this.mainHeader = site;
        return this.source.getInfoBySite('ITEM_HEADER');
      }),
      map((info) => {
        this.headerItems = info;
      })
    ).subscribe(() => {}, (err) => {
      console.log(err);
    });
  }

  navigate(categoria: Categoria): void {
    if (!categoria.CSI_EXT_BTN) {
      this.router.navigate([`produtos/${categoria.CSI_ID}`]);
    } else {
      window.open(`${categoria.CSI_EXT_LINK}`, '_blank');
    }
  }

  navigateTo(): void {
    this.router.navigate([`produtos`]);
  }

  onChangePlace(): void {
    document.getElementById('navbarToggleExternalContent').className = 'collapse';
    document.getElementsByClassName('navbar-toggler')[0].className += ' collapsed';
  }

}
