import { Localizacao } from './../interfaces';
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbAlertConfig } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';
import { FreSourceService } from '../fre-source.service';
import { map, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-contato',
  templateUrl: './contato.component.html',
  styleUrls: ['./contato.component.scss', '../shared/animations.component.scss']
})
export class ContatoComponent implements OnInit {

  message: string;
  siteKey: string;

  showError = false;
  showSuccess = false;
  sent = false;
  isLoading = false;

  localizacao: Localizacao;

  constructor(private source: FreSourceService,
              private alert: NgbAlertConfig) {
    alert.dismissible = false;
    this.siteKey = '6Lfh76YUAAAAAG4H3x1FufvO7npTvp4mANtWoW7u';
  }

  ngOnInit(): void {
    this.source.getInfoBySite('LOCALIZACAO').subscribe((res) => {
      this.localizacao = res[0];
    }, (err) => {
      console.log(err);
    });
  }

  onContact(form: NgForm): void {
    if (this.isLoading) {
      return;
    }

    if (form.invalid) {
      this.showError = true;
      this.message = 'Há campos inválidos ou não preenchidos. Por favor, tente novamente.';
      return;
    }

    form.value.receiver = environment.receiverEmail;

    this.isLoading = true;
    this.source.checkCaptcha(form.value.recaptcha).pipe(
      switchMap(() => {
        return this.source.sendMail(form.value);
      }),
      map(() => {})
    ).subscribe(() => {
      this.message = 'Mensagem enviada com sucesso! Responderemos assim que possível.';
      this.sent = true;
      this.showSuccess = true;
      this.isLoading = false;
    }, (err) => {
      this.showError = true;
      this.message = err.error;
      this.isLoading = false;
    });
  }
}
