<div class="universal">
  <div class="universal-desktop">
    <footer class="footer-box-desktop">
      <div class="fre-badge" *ngIf="descricao">
        <img [src]="img" alt="Logotipo">
        <p>
          {{descricao.DFT_DESCRICAO}}
        </p>
      </div>
      <div class="footer-box-content">
        <div class="ui three column relaxed divided grid">
          <div *ngFor="let item of footer" class="column">
            <h4 class="ui header">{{item.CFT_TITULO}}</h4>
            <div class="ui link list">
              <a *ngFor="let singleItem of item.ITEM" class="item" (click)="navigate(singleItem)">{{singleItem.IFT_DESCRICAO}}</a>
            </div>
          </div>
        </div>
      </div>
    </footer>
    <div class="footer-fre-d">
      <div class="footer-fre-description-d">
        <p class="item">Copyright &copy; {{year}} {{name}}.</p>
      </div>
    </div>
  </div>
</div>



<footer class="footer-box-mobile">
  <div class="fre-badge" *ngIf="descricao">
    <img [src]="img" alt="Logotipo">
    <p>
      {{descricao.DFT_DESCRICAO}}
    </p>
  </div>
  <div class="ui vertical menu box-mobile-content">
    <div *ngFor="let item of footer" class="item">
      <div class="header">{{item.CFT_TITULO}}</div>
      <div class="menu">
        <a *ngFor="let singleItem of item.ITEM" class="item" (click)="navigate(singleItem)">{{singleItem.IFT_DESCRICAO}}</a>
      </div>
    </div>
  </div>
  <div class="footer-fre">
    <div class="footer-fre-description">
      <p class="item">Copyright &copy; {{year}} {{name}}.</p>
    </div>
  </div>
</footer>
