import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { map, switchMap } from 'rxjs/operators';
import { FreSourceService } from '../fre-source.service';
import { ItemFooter } from '../interfaces';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  year = new Date().getFullYear();
  name: string;
  img: string;

  footer = [];
  itens = [];
  descricao;

  constructor(private source: FreSourceService,
              private router: Router) { }

  ngOnInit(): void {
    this.source.getSiteInfo().subscribe((res) => {
      this.name = res.SIT_DESCRICAO;
      this.img = res.SIT_IMAGEM;
    });

    this.source.getInfoBySite('CATEGORIA_FOOTER').pipe(
      switchMap((foot) => {
        this.footer = foot;
        return this.source.getInfoBySite('DESCRICAO_FOOTER');
      }),
      switchMap((desc) => {
        this.descricao = desc[0];
        return this.source.getInfoBySite('ITEM_FOOTER');
      }),
      map((info) => {
        this.itens = info;
      })
    ).subscribe(() => {
      this.footer.forEach((down) => {
        const arr = this.itens.filter((f) => f.IFT_CFT_ID === down.CFT_ID);
        down.ITEM = arr;
      });
    }, (err) => {
      console.log(err);
    });
  }

  navigate(item: ItemFooter): void {
    if (item.IFT_EXT_BTN) {
      window.location.href = item.IFT_LINK;
    } else {
      this.router.navigate([item.IFT_LINK]);
      window.scroll(0, 0);
    }
  }

}
