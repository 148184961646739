import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss', 'shared/animations.component.scss']
})
export class AppComponent implements OnInit {

  ngOnInit(): void {
    window.onscroll = () => {
      this.scrollFunction();
    };
  }

  scrollFunction(): void {
    if (document.body.scrollTop > 100 || document.documentElement.scrollTop > 100) {
      document.getElementById('top-btn').style.display = 'block';
      document.getElementById('top-btn').className = 'animate-up';
    } else {
      document.getElementById('top-btn').style.display = 'none';
    }
  }

  top(): void {
    window.scroll(0, 0);
  }
}
