<div class="contato">
  <div class="formulario">
    <form (submit)="onContact(contactForm)" #contactForm="ngForm">
      <div class="ui label fade-in" style="animation-delay: 50ms;">
        <i class="map marked icon"></i>
        Localização
        <br>
        <p *ngIf="localizacao">
          {{localizacao.LCL_TELEFONE_P}}
          <br *ngIf="localizacao.LCL_TELEFONE_S">
          {{localizacao.LCL_TELEFONE_S}}
          <br>
          {{localizacao.LCL_EMAIL}}
          <br>
          {{localizacao.LCL_ENDERECO}}
        </p>
      </div>

      <div *ngIf="!showSuccess && !sent" class="animate-up" style="animation-delay: 150ms;">

        <p>Os campos com um asterísco são obrigatórios.</p>

        <div class="ui left icon input">
          <input ngModel type="text" name="nome" placeholder="Nome *" #nome="ngModel" required>
          <i class="user icon"></i>
        </div>
        <div class="ui left icon input">
          <input ngModel type="email" name="email" placeholder="E-mail *" #email="ngModel"
                pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"required>
          <i class="mail icon"></i>
        </div>
        <!--
        <div class="ui left icon input">
          <input ngModel type="text" name="empresa" placeholder="Empresa" #empresa="ngModel">
          <i class="building icon"></i>
        </div>
        <div class="ui left icon input">
          <input ngModel type="tel" name="telefone" placeholder="Telefone" #telefone="ngModel">
          <i class="phone icon"></i>
        </div> -->
        <div class="ui left icon input">
          <input ngModel type="text" name="assunto" placeholder="Assunto *" #assunto="ngModel" required>
          <i class="comment icon"></i>
        </div>
        <div class="ui left icon input">
          <input ngModel type="text" name="mensagem" placeholder="Mensagem *" #mensagem="ngModel" required>
          <i class="comment dots icon"></i>
        </div>
        <ngx-recaptcha2
          ngModel
          name="recaptcha"
          #recaptcha="ngModel"
          [siteKey]="siteKey"
          required>
        </ngx-recaptcha2>

        <button class="ui button" type="submit">Enviar</button>
      </div>
      <p *ngIf="showError && !sent">
        <ngb-alert type="danger">{{message}}</ngb-alert>
      </p>
      <p *ngIf="showSuccess && sent">
        <ngb-alert type="success">{{message}}</ngb-alert>
      </p>

      <div class="ui segment" *ngIf="!showError && !showSuccess && !sent && isLoading">
          <div class="ui text active loader">Enviando e-mail, aguarde...</div>
      </div>

    </form>
  </div>
  <div class="google-maps">
    <div class="mapouter">
      <div class="gmap_canvas"><iframe id="gmap_canvas"
          src="https://maps.google.com/maps?q=fre%20inform%C3%A1tica,%20boituva&t=&z=13&ie=UTF8&iwloc=&output=embed"
          frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe>
      </div>
    </div>
