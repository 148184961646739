import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Avaliacao, Categoria, Produto, Site, TipoProduto } from './interfaces';
import { NgForm } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class FreSourceService {

  constructor(private http: HttpClient) { }

  getSiteInfo(): Observable<Site> {
    const obj = `?ID=${environment.siteId}`;
    return this.http.get<Site>(`${environment.apiURL}getSiteInfo${obj}`);
  }

  getUrlImageBySite(screen: string): Observable<string[]> {
    const obj = `?SITE_ID=${environment.siteId}&SCREEN=${screen}`;
    return this.http.get<string[]>(`${environment.apiURL}getImageBySite${obj}`);
  }

  getInfoByIDAndSite(screen: string, searchID: number): Observable<any[]> {
    const obj = `?SITE_ID=${environment.siteId}&SCREEN=${screen}&SEARCH_ID=${searchID}`;
    return this.http.get<any[]>(`${environment.apiURL}getInfoByIDAndSite${obj}`);
  }

  getInfoBySite(screen: string): Observable<any[]> {
    const obj = `?SITE_ID=${environment.siteId}&SCREEN=${screen}`;
    return this.http.get<any[]>(`${environment.apiURL}getInfoBySite${obj}`);
  }

  getCategorias(): Observable<Categoria[]> {
    const obj = `?ID=${environment.siteId}`;
    return this.http.get<Categoria[]>(`${environment.apiURL}getCategoriasBySite${obj}`);
  }

  getCategoriaById(id: string): Observable<Categoria> {
    const obj = `?ID=${environment.siteId}&CID=${id}`;
    return this.http.get<Categoria>(`${environment.apiURL}getCategoriaById${obj}`);
  }

  getTiposProdutoByCategoria(categoria: string): Observable<TipoProduto[]> {
    const obj = `?ID=${environment.siteId}&CID=${categoria}`;
    return this.http.get<TipoProduto[]>(`${environment.apiURL}getTiposProdutoByCategoria${obj}`);
  }

  getProdutosByTipo(tipo: string, categoria: string): Observable<Produto[]> {
    const obj = `?ID=${environment.siteId}&TID=${tipo}&CID=${categoria}`;
    return this.http.get<Produto[]>(`${environment.apiURL}getProdutosByTipo${obj}`);
  }

  getProdutoById(id: string, tipo: string, categoria: string): Observable<Produto> {
    const obj = `?ID=${environment.siteId}&PID=${id}&TID=${tipo}&CID=${categoria}`;
    return this.http.get<Produto>(`${environment.apiURL}getProdutoById${obj}`);
  }

  addAvaliacao(obj: Avaliacao): Observable<any> {
    return this.http.post<any>(`${environment.apiURL}addAvaliacao`, obj);
  }

  getAvaliacoes(id: string, tipo: string, categoria: string): Observable<Avaliacao[]> {
    const obj = `?ID=${environment.siteId}&PID=${id}&TID=${tipo}&CID=${categoria}`;
    return this.http.get<Avaliacao[]>(`${environment.apiURL}getAvaliacoes${obj}`);
  }

  getProdutoImagens(id: string): Observable<string[]> {
    const obj = `?ID=${environment.siteId}&PID=${id}`;
    return this.http.get<string[]>(`${environment.apiURL}getImagensProdutoBySite${obj}`);
  }

  getImagensProduto(): Observable<string[]> {
    const obj = `?ID=${environment.siteId}`;
    return this.http.get<string[]>(`${environment.apiURL}getImagensProduto${obj}`);
  }

  checkCaptcha(token: string): Observable<any> {
    return this.http.post<any>(`${environment.apiURL}checkCaptcha`, { token });
  }

  sendMail(obj: NgForm): Observable<any> {
    return this.http.post<any>(`${environment.apiURL}sendMail`, obj);
  }
}
